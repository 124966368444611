@tailwind base;
@tailwind components;
@tailwind utilities;


.hidden{
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }
  .geosuggest {
    font-size: 18px;
    font-size: 1rem;
    position: relative;
    width: 100%;
    text-align: left;
  }
  
  .geosuggest__suggests {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #f86217;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
  }
  
  .statTable{
    margin-top:2px;
  }